import React from 'react';
import CharacterCount from './CharacterCount';
import './HorizontalHeader.css';
import { ClockIcon, ImportIcon, ViewIcon, OptionIconSmall} from './SVGLibrary.js';


const HorizontalHeader = ({ characterCount, isSaving }) => {
  return (
    <div className="horizontal-header">
        <div className="header-project">
            <input type="text" placeholder="Project Name" id='project-name' autoComplete='off'/>
            <div className="divider"></div>
            <div className="header-project-buttons">
                <button className="history-button">
                    <ClockIcon />
                </button>
                <button className="import-button">
                    <ImportIcon />
                </button>
                <button className="view-button">
                    <ViewIcon />
                </button>
            </div>
        </div>

        <div className="header-right">
            <CharacterCount count={characterCount} isSaving={isSaving} />
            <button className="profile-icon-header"></button>
            <div className="divider"></div>
            <button className="option-button">
                <OptionIconSmall />
            </button>
        </div>
    </div>
  );
};

export default HorizontalHeader;
