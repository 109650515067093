const AI_API_URL = process.env.REACT_APP_AI_API_URL || 'https://pagerift-writer.ue.r.appspot.com/api';

const streamToText = async (response) => {
  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  let result = '';

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    result += decoder.decode(value, { stream: true });
  }

  return result.split('---').filter(text => text.trim() !== '');
};

export const changeTone = async (text, tone) => {
  try {
    let endpoint;
    switch (tone) {
      case 'happy':
        endpoint = 'happy_stream';
        break;
      case 'sad':
        endpoint = 'sad_stream';
        break;
      case 'angry':
        endpoint = 'angry_stream';
        break;
      case 'scared':
        endpoint = 'scared_stream';
        break;
      case 'tender':
        endpoint = 'tender_stream';
        break;
      case 'excited':
        endpoint = 'excited_stream';
        break;
      default:
        throw new Error('Invalid tone option');
    }

    const response = await fetch(`${AI_API_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text, num_versions: 4 }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const results = await streamToText(response);
    return results;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
