import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import './ProjectSelection.css';
import { PlotIcon, EllipseIcon, LightModeIcon, OptionIcon, EllipseSpacer, SingleEllipse, CardTimeIcon, CardBookmarkIcon, CardHomeIcon } from './SVGLibrary';

const ProjectSelection = ({ user, onProjectSelect }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchProjects();
  }, [user]); // Add user as a dependency

  const fetchProjects = async () => {
    setLoading(true);
    setError(null);
    console.log('Fetching projects for user:', user.id);
    try {
      const { data, error } = await supabase
        .from('projects')
        .select('*')
        .eq('user_id', user.id);

      if (error) {
        throw error;
      }

      console.log('Fetched projects:', data);
      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError('Failed to load projects. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const createNewProject = async () => {
    try {
      const newProject = {
        user_id: user.id,
        title: 'New Project',
        description: 'A new writing project',
        content: { type: "doc", content: [{ type: "paragraph" }] }
      };

      const { data, error } = await supabase
        .from('projects')
        .insert([newProject])
        .single();

      if (error) {
        throw error;
      }

      console.log('Created new project:', data);
      
      if (data) {
        console.log('Calling onProjectSelect with:', data);
        onProjectSelect(data);
      } else {
        console.error('New project data is null');
      }
    } catch (error) {
      console.error('Error creating new project:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  if (loading) {
    return <div>Loading projects...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="project-selection">
      <div className="project-selection-sidebar">
        <header className="project-selection-header">
          <div className="profile-icon"></div>
          <button className="sign-out-button" onClick={handleSignOut}>Sign Out</button>
        </header>
        <main className="project-selection-main">
          <div className="project-options">
            <button className="project-recents project-button">
              <span>Recents</span>
            </button>
            <button className="project-bookmarks project-button">
                <span>Bookmarks</span>
            </button>
            <button className="project-discover project-button">
                <span>Discover</span>
            </button>
          </div>

          <div className="project-library">
            <h2 className="library-title">Library</h2>
            <div className="category-list">
                <button className="category-item">
                    <SingleEllipse />
                    <span>Category name</span>
                </button>
                <button className="category-item">
                    <SingleEllipse />
                    <span>Category name</span>
                </button>

            </div>
          </div>
         
        </main>
        <footer className="project-selection-footer">
            <button className="footer-button">
                <PlotIcon />
                <span>Trash</span>
            </button>
         
        </footer>
      </div>
      <div className="project-selection-content">
        <header className="project-content-header">
            <button className="new-project" onClick={createNewProject}>
                <span>New project</span>
            </button>
            <button className="header-button light-mode">
              <LightModeIcon />
            </button>
            <button className="header-button">
              <OptionIcon />
            </button>
        </header>
        <div className="project-card-wrapper">
            {projects.map((project) => (
              project && (
                <div key={project.id} className="project-card" onClick={() => onProjectSelect(project)}>
                  <div className="project-card-header">
                    <div className='project-card-image'>
                      <img src={project.image || '/thumbnail.png'} alt={project.title || 'Project thumbnail'} />
                    </div>
                    <span>{project.title}</span>
                  </div>
                  <div className="card-body">
                    <span>{project.description}</span>
                  </div>
                  <div className="card-footer">
                    <div className="card-edited">
                    <CardTimeIcon />
                      <span>{new Date(project.updated_at).toLocaleString()}</span>
                    </div>
                    <div className="card-options">
                      <button className="card-option card-option-home">
                        <CardHomeIcon />
                      </button>
                      <button className="card-option card-option-bookmark">
                        <CardBookmarkIcon />
                      </button>
                    </div>
                   
                  </div> 
                </div>
              )
            ))}
        </div>
        
      </div>
    </div>
  );
};

export default ProjectSelection;
