import React, { useState, useEffect, useCallback, useRef } from 'react';
import { supabase } from '../supabaseClient';
import './LeftSidebar.css';
import { SidebarCollapse, HomeButtonIcon, PageListIcon, HelperIcon, HeaderEllipseIcon, WorldbuildingIcon, WorldGenreIcon, WorldStyleIcon, WorldIdeaIcon, WorldGeneralTooltip, WorldCharactersTooltip, WorldForgeTooltip, WorldSummaryTooltip, SummaryIcon, WorldBuilderIcon, OptionsPlusIcon, CharacterNameIcon } from './SVGLibrary';

const LeftSidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [extendedContent, setExtendedContent] = useState(null);
  const [selectedWorldbuildingOption, setSelectedWorldbuildingOption] = useState(null);
  const [selectedWorldforgeOption, setSelectedWorldforgeOption] = useState(null);
  const [tooltipContent, setTooltipContent] = useState(
    "Tell us about your story's universe. Worldbuilder learns from your details to enhance your writing experience. It powers smarter rewrites and helps maintain consistency throughout your narrative. The more you share, the better it understands your unique storytelling world."
  );
  const [tooltipBackground, setTooltipBackground] = useState("rgba(124, 168, 114, 0.10)");
  const [tooltipIcon, setTooltipIcon] = useState(WorldGeneralTooltip);
  const [worldId, setWorldId] = useState(null);
  const [worldData, setWorldData] = useState({});
  const [characters, setCharacters] = useState([]);
  const saveTimeout = useRef(null);

  useEffect(() => {
    const fetchOrCreateWorld = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        let { data: world, error } = await supabase
          .from('worlds')
          .select('*')
          .eq('user_id', user.id)
          .single();

        if (error || !world) {
          const { data: newWorld, error: createError } = await supabase
            .from('worlds')
            .insert({ user_id: user.id, name: 'My World' })
            .single();

          if (createError) {
            console.error('Error creating world:', createError);
            return;
          }
          world = newWorld;
        }

        setWorldId(world.id);
        await fetchWorldDetails(world.id);
      }
    };

    fetchOrCreateWorld();
  }, []);

  const fetchWorldDetails = async (worldId) => {
    try {
      const { data, error } = await supabase
        .from('world_details')
        .select('*')
        .eq('world_id', worldId);
  
      if (error) throw error;
  
      const worldDataObj = {};
      data.forEach(detail => {
        worldDataObj[detail.category] = detail.data;
      });
      setWorldData(worldDataObj);
      
      // Set characters if they exist in the world data
      if (worldDataObj.characters) {
        setCharacters(worldDataObj.characters);
      }
    } catch (error) {
      console.error('Error fetching world details:', error);
    }
  };

  const saveWorldDetail = async (category, data) => {
    console.log('Saving world detail:', { category, data });
    if (!worldId) {
      console.error('No world ID available');
      return;
    }
  
    try {
      // First, try to fetch the existing record
      const { data: existingData, error: fetchError } = await supabase
        .from('world_details')
        .select('*')
        .eq('world_id', worldId)
        .eq('category', category)
        .maybeSingle();
  
      if (fetchError) throw fetchError;
  
      if (existingData) {
        // Update existing record
        const { error: updateError } = await supabase
          .from('world_details')
          .update({ data })
          .eq('id', existingData.id);
  
        if (updateError) throw updateError;
      } else {
        // Insert new record
        const { error: insertError } = await supabase
          .from('world_details')
          .insert({ world_id: worldId, category, data });
  
        if (insertError) throw insertError;
      }
  
      // After successfully saving the world detail, generate embeddings
      const response = await fetch('/api/generate_embeddings', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ worldId }),
      });
      const result = await response.json();
      console.log('Embedding generation response:', result);
    } catch (error) {
      console.error('Error saving world detail or generating embedding:', error);
    }
  };

  const handleWorldbuildingChange = (category, field, value) => {
    const updatedData = {
      ...worldData[category],
      [field]: value
    };
    
    // Update local state immediately for responsive UI
    setWorldData(prevData => ({
      ...prevData,
      [category]: updatedData
    }));

    // Debounce the save operation to reduce database calls
    if (saveTimeout.current) {
      clearTimeout(saveTimeout.current);
    }
    saveTimeout.current = setTimeout(() => {
      saveWorldDetail(category, updatedData);
    }, 500); // Wait 500ms before saving to reduce API calls
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleExtendedContent = (content) => {
    setExtendedContent(prevContent => {
      if (prevContent === content) {
        setSelectedWorldbuildingOption(null);
        return null;
      } else {
        if (content === 'worldbuilding') {
          setSelectedWorldbuildingOption('general');
          handleWorldbuildingOptionClick('general');
        }
        return content;
      }
    });
  };

  const handleWorldbuildingOptionClick = (option) => {
    setSelectedWorldbuildingOption(selectedWorldbuildingOption === option ? null : option);
    
    if (option === 'general') {
      setTooltipContent("Share your story's universe. Worldbuilder learns from your details to enhance your writing experience. It powers smarter rewrites and maintains consistency throughout your narrative. ");
      setTooltipBackground("rgba(124, 168, 114, 0.10)");
      setTooltipIcon(() => WorldGeneralTooltip);
    } else if (option === 'characters') {
      setTooltipContent("Develop your story's cast in Characters. Create profiles for key roles, tracking personalities, arcs, and relationships. Ensure rich, consistent character portrayals throughout your narrative.");
      setTooltipBackground("#FAF9F1");
      setTooltipIcon(() => WorldCharactersTooltip); 
    } else if (option === 'worldforge') {
      setTooltipContent("Craft your story's universe in Worldforge. Add lore, events, magic, and more to enrich your narrative landscape. It integrates these elements for consistent storytelling and dynamic writing. ");
      setTooltipBackground("rgba(116, 120, 207, 0.10)");
      setTooltipIcon(() => WorldForgeTooltip);
    } else if (option === 'summary') {
      setTooltipContent("This overview helps maintain consistency and reveals worldbuilding opportunities. It serves as a quick reference to keep your story's universe cohesive and captivating.");
      setTooltipBackground("rgba(207, 116, 116, 0.10)");
      setTooltipIcon(() => WorldSummaryTooltip);
    }
  };

  const handleWorldforgeOptionClick = (option) => {
    setSelectedWorldforgeOption(prevOption => prevOption === option ? null : option);
  };

  const autoResizeTextarea = (event) => {
    const textarea = event.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${Math.min(Math.max(textarea.scrollHeight, 96), 192)}px`;
  };

  const addCharacter = () => {
    const newCharacter = { id: Date.now() };
    setCharacters(prevCharacters => [...prevCharacters, newCharacter]);
    handleWorldbuildingChange('characters', characters.length.toString(), newCharacter);
  };

  return (
    <div className="left-sidebar">
      <div className={`left-sidebar-container ${isCollapsed ? 'collapsed' : ''}`}>
        <header className="left-sidebar-header">
          <button className="home-button"><HomeButtonIcon /></button>
          <button className="collapse-button" onClick={toggleCollapse}><SidebarCollapse /></button>
        </header>
        <main className='left-sidebar-main'>
          <h2 className='project-name'><span>Pages</span></h2>
          <div className='page-list'>
            <button className='page-button'><PageListIcon /> <span className='page-name'>The War</span></button>
            <button className='page-button'><PageListIcon /> <span className='page-name'>Duneland</span></button>
          </div>

          <div className='left-sidebar-options'>
            <button className='sidebar-option-button'><span className='sidebar-option-text'>Recents</span></button>
            <button className='sidebar-option-button'><span className='sidebar-option-text'>History</span></button>
            <button className='sidebar-option-button'><span className='sidebar-option-text'>Discover</span></button>
          </div>
        </main>

        <footer className='left-sidebar-footer'>
          <button className="footer-button" onClick={() => toggleExtendedContent('helper')}>
            <HelperIcon /> <span className='footer-text'>Helper</span>
          </button>
          <button className="footer-button" onClick={() => toggleExtendedContent('worldbuilding')}>
            <WorldbuildingIcon /> <span className='footer-text'>Worldbuilding</span>
          </button>
        </footer>
      </div>
      {extendedContent && (
        <div className={`left-sidebar-extended ${extendedContent ? 'open' : ''}`}>
          {extendedContent === 'helper' && (
            <div className="helper-content">
              <h3>Helper Content</h3>
            </div>
          )}
          {extendedContent === 'worldbuilding' && (
            <div className="worldbuilding-content">
              <div className="worldbuilding-title">
                <h3>Worldbuilder</h3>
                <button className="worldbuilding-collapse" onClick={() => toggleExtendedContent('worldbuilding')}><SidebarCollapse /></button>
              </div>
              <div className="worldbuilding-tooltip-container">
                <div className="worldbuilding-tooltip" style={{ background: tooltipBackground }}>
                  {tooltipIcon && tooltipIcon()}
                  <p>{tooltipContent}</p>
                </div>

                <ul className="worldbuilding-options">
                  <li><button onClick={() => handleWorldbuildingOptionClick('general')} className={`worldbuilding-option-button ${selectedWorldbuildingOption === 'general' ? 'active' : ''}`}>General</button></li>
                  <li><button onClick={() => handleWorldbuildingOptionClick('characters')} className={`worldbuilding-option-button ${selectedWorldbuildingOption === 'characters' ? 'active' : ''}`}>Characters</button></li>
                  <li><button onClick={() => handleWorldbuildingOptionClick('worldforge')} className={`worldbuilding-option-button ${selectedWorldbuildingOption === 'worldforge' ? 'active' : ''}`}>Worldforge</button></li>
                  <li><button onClick={() => handleWorldbuildingOptionClick('summary')} className={`worldbuilding-option-button ${selectedWorldbuildingOption === 'summary' ? 'active' : ''}`}>Summary</button></li>
                </ul>
                {selectedWorldbuildingOption && (
                  <div className="worldbuilding-option-content">
                    {selectedWorldbuildingOption === 'general' && (
                      <>
                        <div className="worldbuilding-general-content">
                          <div className="worldbuilding-fields">
                            <div className="worldbuilding-field">
                              <div className='worldbuilding-field-header'>
                                <label><WorldStyleIcon/>Style</label>
                                <button className='world-header-options'><HeaderEllipseIcon/></button>
                              </div>
                              <textarea
                                id='worldbuilding-style'
                                placeholder="Describe your writing style and tone. Consider elements like formality, mood, sentence structure, and word choice."
                                value={worldData.general?.style || ''}
                                onChange={(e) => handleWorldbuildingChange('general', 'style', e.target.value)}
                                onInput={autoResizeTextarea}
                              ></textarea>
                            </div>
                            <div className="worldbuilding-field">
                              <div className='worldbuilding-field-header'>
                                <label><WorldGenreIcon/>Genre</label>
                                <button className='world-header-options'><HeaderEllipseIcon/></button>
                              </div>
                              <textarea
                                id='worldbuilding-genre'
                                placeholder="What's the literary flavor of your tale? Feel free to mix in sub-genres and favorite tropes."
                                value={worldData.general?.genre || ''}
                                onChange={(e) => handleWorldbuildingChange('general', 'genre', e.target.value)}
                                onInput={autoResizeTextarea}
                              ></textarea>
                            </div>
                            <div className="worldbuilding-field">
                              <div className='worldbuilding-field-header'>
                                <label><WorldIdeaIcon />Idea Vault</label>
                                <button className='world-header-options'><HeaderEllipseIcon/></button>
                              </div>
                              <textarea
                                id='worldbuilding-idea'
                                placeholder="Capture everything you know about your story. Pour out your raw thoughts on plot, characters, world, and themes. No structure needed – just let your imagination flow. Your ideas, no matter how rough or random, fuel your story's potential."
                                value={worldData.general?.ideaVault || ''}
                                onChange={(e) => handleWorldbuildingChange('general', 'ideaVault', e.target.value)}
                                onInput={autoResizeTextarea}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {selectedWorldbuildingOption === 'characters' && (
                      <div className="worldbuilding-characters-content">
                        <div className="worldbuilding-characters-title">
                          <div className="worldbuilding-field" id='worldbuilding-characters-field'>
                            <div className='worldbuilding-field-header'>
                              <label><WorldBuilderIcon/>Characters</label>
                              <button className='world-header-options'><HeaderEllipseIcon/></button>
                            </div>
                            <p>Bring your world to life with immersive Locations, rich Lore, powerful Magic, and so much more.</p>
                            <button className="worldbuilding-add-character-button" onClick={addCharacter}><OptionsPlusIcon/>Add Character</button>
                          </div>
                        </div>
                        <div className="worldbuilding-fields" id='character-creation-card-container'>
                          {characters.map((character, index) => (
                            <div key={character.id} className="worldbuilding-field" id='character-creation-card'>
                              <div className='worldbuilding-field-header'>
                                <div className='character-name-header'>
                                  <label><CharacterNameIcon/></label>
                                  <input
                                    type="text"
                                    placeholder="Enter character name"
                                    className="character-name-input"
                                    value={character.name || ''}
                                    onChange={(e) => handleWorldbuildingChange('characters', `${index}.name`, e.target.value)}
                                  />
                                </div>
                                <button className='world-header-options'><HeaderEllipseIcon/></button>
                              </div>
                              <div className="character-details">
                                <div className='character-details-split'>
                                  <div className='character-details-pronouns'>
                                    <label>Pronouns</label>
                                    <textarea
                                      placeholder="Enter pronouns"
                                      value={character.pronouns || ''}
                                      onChange={(e) => handleWorldbuildingChange('characters', `${index}.pronouns`, e.target.value)}
                                      onInput={autoResizeTextarea}
                                    ></textarea>
                                  </div>
                                  <div className='character-details-groups'>
                                    <label>Groups</label>
                                    <textarea
                                      placeholder="Enter groups"
                                      value={character.groups || ''}
                                      onChange={(e) => handleWorldbuildingChange('characters', `${index}.groups`, e.target.value)}
                                      onInput={autoResizeTextarea}
                                    ></textarea>
                                  </div>
                                </div>
                                <div className='character-details-full'>
                                  <label>Personality</label>
                                  <textarea
                                    placeholder="Describe the character's personality"
                                    value={character.personality || ''}
                                    onChange={(e) => handleWorldbuildingChange('characters', `${index}.personality`, e.target.value)}
                                    onInput={autoResizeTextarea}
                                  ></textarea>
                                </div>
                                <div className='character-details-full'>
                                  <label>Background</label>
                                  <textarea
                                    placeholder="Describe the character's background"
                                    value={character.background || ''}
                                    onChange={(e) => handleWorldbuildingChange('characters', `${index}.background`, e.target.value)}
                                    onInput={autoResizeTextarea}
                                  ></textarea>
                                </div>
                                <div className='character-details-full'>
                                  <label>Physical Description</label>
                                  <textarea
                                    placeholder="Describe the character's physical appearance"
                                    value={character.physicalDescription || ''}
                                    onChange={(e) => handleWorldbuildingChange('characters', `${index}.physicalDescription`, e.target.value)}
                                    onInput={autoResizeTextarea}
                                  ></textarea>
                                </div>
                                <div className='character-details-full'>
                                  <label>Dialogue Style</label>
                                  <textarea
                                    placeholder="Describe the character's dialogue style"
                                    value={character.dialogueStyle || ''}
                                    onChange={(e) => handleWorldbuildingChange('characters', `${index}.dialogueStyle`, e.target.value)}
                                    onInput={autoResizeTextarea}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {selectedWorldbuildingOption === 'worldforge' && (
                      <>
                        <div className="worldbuilding-worldforge-content">
                          <div className="worldbuilding-worldforge-fields">
                            <div className="worldbuilding-field" id='worldforge-field'>
                              <div className='worldbuilding-field-header' id='worldforge-header'>
                                <label><WorldBuilderIcon/>Builder</label>
                                <button className='world-header-options'><HeaderEllipseIcon/></button>
                              </div>
                              <div className='worldforge-builder'>
                                <p>Bring your world to life with immersive Locations, rich Lore, powerful Magic, and so much more.</p>
                                <ul className='worldforge-builder-options'>
                                  {['custom', 'setting', 'keyEvent', 'culture', 'government', 'organization', 'economy', 'religion', 'magicSystem', 'technology', 'item', 'conflict', 'geography', 'history', 'mythology'].map((option) => (
                                    <li key={option}>
                                      <button className='worldforge-builder-option' onClick={() => handleWorldforgeOptionClick(option)}>
                                        <OptionsPlusIcon/>{option.charAt(0).toUpperCase() + option.slice(1)}
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        {selectedWorldforgeOption && (
                          <div className={`worldforge-${selectedWorldforgeOption}`}>
                            <div className="worldbuilding-field" id={`worldforge-${selectedWorldforgeOption}-field`}>
                              <div className='worldbuilding-field-header'>
                                <label><WorldBuilderIcon/>{selectedWorldforgeOption.charAt(0).toUpperCase() + selectedWorldforgeOption.slice(1)}</label>
                                <button className='world-header-options'><HeaderEllipseIcon/></button>
                              </div>
                              <div className={`worldforge-${selectedWorldforgeOption}-content`}>
                                {['name', 'description', 'details', 'impact'].map((field) => (
                                  <div key={field}>
                                    <label>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                                    <textarea
                                      placeholder={`Enter ${selectedWorldforgeOption} ${field}`}
                                      value={worldData[selectedWorldforgeOption]?.[field] || ''}
                                      onChange={(e) => handleWorldbuildingChange(selectedWorldforgeOption, field, e.target.value)}
                                      onInput={autoResizeTextarea}
                                    ></textarea>
                                  </div>
                                ))}
                                <div className={`worldforge-${selectedWorldforgeOption}-footer`}>
                                  <button className={`worldforge-${selectedWorldforgeOption}-save`}><OptionsPlusIcon/>Add Trait</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {selectedWorldbuildingOption === 'summary' && (
                      <>
                        <div className="worldbuilding-summary-content">
                          <div className="worldbuilding-summary-fields">
                            <div className="worldbuilding-field">
                              <div className='worldbuilding-field-header'>
                                <label><SummaryIcon/>Summary</label>
                                <button className='world-header-options'><HeaderEllipseIcon/></button>
                              </div>
                              <textarea
                                id='worldbuilding-summary'
                                placeholder="Provide a brief overview of your world and story. Highlight key elements that define your narrative universe."
                                value={worldData.summary?.content || ''}
                                onChange={(e) => handleWorldbuildingChange('summary', 'content', e.target.value)}
                                onInput={autoResizeTextarea}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LeftSidebar;