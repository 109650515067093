import React, { useState } from 'react';
import './RightSidebar.css';
import { LightModeIcon, OptionIcon, SidebarBookmarksIcon, SidebarRedoIcon } from './SVGLibrary';
import RewriteCard from './RewriteCard';

const RightSidebar = ({ rewriteOptions, originalText, operationType, onInsert }) => {
  const [bookmarkedRewrites, setBookmarkedRewrites] = useState([]);
  const [activeTab, setActiveTab] = useState('history');

  const handleBookmark = (rewrittenText) => {
    setBookmarkedRewrites(prev => [...prev, { originalText, rewrittenText, operationType }]);
  };

  const handleRemoveBookmark = (index) => {
    setBookmarkedRewrites(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <div className={`right-sidebar`}>
      <div className="right-sidebar-content">
        <div className="sidebar-header">
         <div className="sidebar-header-title">
          <img src= "/EllipseCircle.png" alt="Option Icon" /> 
          <span>Rewrites</span>
        </div>
        <div className="sidebar-header-options">
          <button className= "sidebar-redo-button">
            <SidebarRedoIcon />
          </button>
          <button className= "sidebar-bookmarks-button">
            <SidebarBookmarksIcon />
          </button>
        </div>
       </div>
        <div className="rewrite-options">
          {rewriteOptions.map((rewrittenText, index) => (
            <RewriteCard
              key={index}
              originalText={originalText}
              rewrittenText={rewrittenText}
              onInsert={onInsert}
              operationType={operationType}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;