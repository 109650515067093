import React, { useState } from 'react';
import styles from '../AuthComponent.module.css';
import '../AuthComponent.module.css';
import { PageriftLogo } from '../SVGLibrary';

const CodeScreen = ({ onVerify, onResendCode }) => {
  const [code, setCode] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onVerify(code);
  };

  return (
    <div className={styles.authForm}>
      <PageriftLogo />
      <h2 className={styles.authTitle}>Check your email</h2>
      <p className={styles.authSubtitle}>We've sent a code to alim@pagerift.com</p>
      <form onSubmit={handleSubmit} className="form-inputs, form-code-inputs">
        <div id="form-code" className={`${styles.inputEmail}`}>
          <label htmlFor="code" className={styles.inputLabel}>Enter Code</label>
          <input
            id="code"
            type="text"
            placeholder="-- -- -- --"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className={styles.input}
          />
        </div>
        <button type="submit" className={styles.button}>Continue</button>
        <button onClick={onResendCode} className={styles.switchButton}>
          Send code again
        </button>
      </form>
      <p className={styles.switchMode}>
        Back to Log In
      </p>
    </div>
  );
};

export default CodeScreen;
