import React, { useState, useEffect } from 'react';
import MainEditor from './components/MainEditor';
import AuthComponent from './components/AuthComponent';
import ProjectSelection from './components/ProjectSelection';
import { supabase } from './supabaseClient';

function App() {
  const [user, setUser] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkUser();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_OUT') {
        setUser(null);
        setSelectedProject(null);
      } else if (event === 'SIGNED_IN') {
        setUser(session.user);
      }
    });

    return () => {
      if (authListener && authListener.unsubscribe) {
        authListener.unsubscribe();
      }
    };
  }, []);

  const checkUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setUser(user);
    setLoading(false);
  };

  const handleLogin = (loggedInUser) => {
    setUser(loggedInUser);
  };

  const handleProjectSelect = (project) => {
    console.log('Project selected:', project);
    setSelectedProject(project);
  };

  const handleSignOut = () => {
    setUser(null);
    setSelectedProject(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      {!user ? (
        <AuthComponent onLogin={handleLogin} />
      ) : !selectedProject ? (
        <ProjectSelection user={user} onProjectSelect={handleProjectSelect} onSignOut={handleSignOut} />
      ) : (
        <MainEditor user={user} project={selectedProject} />
      )}
    </div>
  );
}

export default App;