import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Heading from '@tiptap/extension-heading';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import CharacterCount from '@tiptap/extension-character-count';
import FloatingToolbar from './FloatingToolbar';
import CharacterCountComponent from './CharacterCount';
import './EditorContent.css';
import { ExpandIcon, RewriteIconLight, ChangeToneIcon, HappyToneIcon, SadToneIcon, AngryToneIcon, ScaredToneIcon, TenderToneIcon, ExcitedToneIcon } from './SVGLibrary.js';
import { bubbleMenuRewrite } from '../services/bubbleMenuService';
import { changeTone } from '../services/changeToneService';
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Highlight from '@tiptap/extension-highlight'

const TiptapEditor = forwardRef(({ editorState, setEditorState, title, onTitleChange, onRewrite, setRewriteOptions, setOriginalText, setOperationType, setCharacterCount, worldId }, ref) => {
    const [activeDropdown, setActiveDropdown] = useState(null);

    const editor = useEditor({
        extensions: [
          StarterKit,
          Heading.configure({
            levels: [1, 2, 3],
          }),
          Document,
          Paragraph,
          Text,
          CharacterCount.configure({
          }),
          Placeholder.configure({
            placeholder: ({ node }) => {
              if (node.type.name === 'heading' && node.attrs.level === 1) {
                return 'Write your title here...'
              }
              return 'Write your story here...'
            },
          }),
          Bold,
          Italic,
          Underline,
          Highlight,
        ],
        content: editorState || { type: 'doc', content: [{ type: 'paragraph' }] },
        onUpdate: ({ editor }) => {
            const count = editor.storage.characterCount.characters();
            setCharacterCount(count);
            setEditorState(editor.getJSON());
        },
    })

    useImperativeHandle(ref, () => ({
        ...editor,
        state: editor?.state
    }));

    useEffect(() => {
      if (editor && ref) {
        ref.current = editor;
      }
    }, [editor, ref]);

    useEffect(() => {
      if (editor && editorState) {
        console.log('Setting editor content:', editorState);
        editor.commands.setContent(editorState);
      }
    }, [editor, editorState]);

    useEffect(() => {
      if (editor) {
        const initialCount = editor.storage.characterCount.characters();
        setCharacterCount(initialCount);
      }
    }, [editor, setCharacterCount]);

    const shouldShowBubbleMenu = useCallback(() => {
        // Only show the bubble menu if there's a text selection
        // and no dropdown is currently active
        return !editor.state.selection.empty && !activeDropdown;
    }, [editor, activeDropdown]);

    const closeBubbleMenu = useCallback(() => {
        if (editor) {
            const { to } = editor.state.selection;
            editor.commands.setTextSelection({ from: to, to: to });
            editor.commands.blur();
            setActiveDropdown(null);
        }
    }, [editor]);

    const handleBubbleMenuRewrite = async (option) => {
        if (!editor) return;
        
        const { from, to } = editor.state.selection;
        const selectedText = editor.state.doc.textBetween(from, to);
        
        closeBubbleMenu();

        try {
            const rewrittenTexts = await bubbleMenuRewrite(selectedText, option, worldId);
            
            setOriginalText(selectedText);
            setRewriteOptions(rewrittenTexts);
            setOperationType('rewrite');
        } catch (error) {
            console.error('Error rewriting text:', error);
        }
    };

    const handleToneChange = async (tone) => {
        if (!editor) return;
        
        const { from, to } = editor.state.selection;
        const selectedText = editor.state.doc.textBetween(from, to);
        
        closeBubbleMenu();

        try {
            const changedToneTexts = await changeTone(selectedText, tone);
            
            setOriginalText(selectedText);
            setRewriteOptions(changedToneTexts);
            setOperationType('toneChange');
        } catch (error) {
            console.error('Error changing tone:', error);
        }
    };

    useEffect(() => {
        if (editor) {
            const handleSelectionChange = () => {
                if (editor.state.selection.empty) {
                    setActiveDropdown(null);
                }
            };

            editor.on('selectionUpdate', handleSelectionChange);

            return () => {
                editor.off('selectionUpdate', handleSelectionChange);
            };
        }
    }, [editor]);

    return (
        <div className="editor-container">
            <div className="editor-content">
                <input 
                    className="editor-title" 
                    type="text" 
                    placeholder="Write your title here..." 
                    value={title}
                    onChange={onTitleChange}
                />
                {editor && (
                    <>
                        <BubbleMenu 
                            editor={editor} 
                            tippyOptions={{ duration: 100 }}
                            shouldShow={shouldShowBubbleMenu}
                        >
                            <div className="bubble-menu">
                                <div className="bubble-menu-item">
                                    <button className="bubble-menu-button expand-button" onClick={() => setActiveDropdown('expand')}>
                                        <ExpandIcon /> <span className="bubble-menu-button-text">Expand</span>
                                    </button>
                                    {activeDropdown === 'expand' && (
                                        <div className="dropdown-menu">
                                            <button>Option 1</button>
                                            <button>Option 2</button>
                                            <button>Option 3</button>
                                        </div>
                                    )}
                                </div>

                                <div className="bubble-menu-item">
                                    <button className="bubble-menu-button rewrite-button" onClick={() => setActiveDropdown('rewrite')}>
                                        <RewriteIconLight /> <span className="bubble-menu-button-text">Rewrite</span>
                                    </button>
                                    {activeDropdown === 'rewrite' && (
                                        <div className="dropdown-menu">
                                            <button onClick={() => handleBubbleMenuRewrite('rephrase')}><RewriteIconLight /> <span className="bubble-menu-button-text">Rephrase</span></button>
                                            <button onClick={() => handleBubbleMenuRewrite('short')}><RewriteIconLight /> <span className="bubble-menu-button-text">Short</span></button>
                                            <button onClick={() => handleBubbleMenuRewrite('distinctive')}><RewriteIconLight /> <span className="bubble-menu-button-text">Distinctive</span></button>
                                            <button onClick={() => handleBubbleMenuRewrite('emotional')}><RewriteIconLight /> <span className="bubble-menu-button-text">Emotional</span></button>
                                            <button onClick={() => handleBubbleMenuRewrite('romantic')}><RewriteIconLight /> <span className="bubble-menu-button-text">Romantic</span></button>
                                        </div>
                                    )}
                                </div>

                                <div className="bubble-menu-item">
                                    <button className='bubble-menu-button change-tone-button' onClick={() => setActiveDropdown('changeTone')}>
                                        <ChangeToneIcon /> <span className="bubble-menu-button-text">Change Tone</span>
                                    </button>
                                    {activeDropdown === 'changeTone' && (
                                        <div className="dropdown-menu">
                                            <button onClick={() => handleToneChange('happy')}><HappyToneIcon /> <span className="bubble-menu-button-text">Happy</span></button>
                                            <button onClick={() => handleToneChange('sad')}><SadToneIcon /> <span className="bubble-menu-button-text">Sad</span></button>
                                            <button onClick={() => handleToneChange('angry')}><AngryToneIcon /> <span className="bubble-menu-button-text">Angry</span></button>
                                            <button onClick={() => handleToneChange('scared')}><ScaredToneIcon /> <span className="bubble-menu-button-text">Scared</span></button>
                                            <button onClick={() => handleToneChange('tender')}><TenderToneIcon /> <span className="bubble-menu-button-text">Tender</span></button>
                                            <button onClick={() => handleToneChange('excited')}><ExcitedToneIcon /> <span className="bubble-menu-button-text">Excited</span></button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </BubbleMenu>
                        <FloatingToolbar onRewrite={onRewrite} editor={editor} />
                    </>
                )}
                <EditorContent editor={editor} />
            </div>
        </div>
    );
});

export default TiptapEditor;