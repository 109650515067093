import React, { useState } from 'react';
import styles from '../AuthComponent.module.css';
import '../AuthComponent.module.css';
import { PageriftLogo } from '../SVGLibrary';
import { GoogleLogo } from '../SVGLibrary';

const SignUpScreen = ({ onSignUp, onSwitchToSignIn, onGoogleSignIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSignUp(email, password);
  };

  return (
    <div className={styles.authForm}>
      <PageriftLogo />
      <h2 className={styles.authTitle}>Create Account</h2>
      <p className={styles.authSubtitle}>Sign up to use the worlds best writer</p>
      <button onClick={onGoogleSignIn} className={`${styles.button} ${styles.googleButton}`}>
        <GoogleLogo />
        Continue with Google
      </button>

      <div className={styles.divider}>
        or
      </div>
      <form onSubmit={handleSubmit} className="form-inputs">
        <div id="form-email" className={`${styles.inputEmail}`}>
          <label htmlFor="email" className={styles.inputLabel}>Email</label>
          <input
            id="email"
            type="email"
            placeholder="Type your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
          />
        </div>
        <div id="form-password" className={`${styles.inputPassword}`}>
          <label htmlFor="password" className={styles.inputLabel}>Password</label>
          <input
            id="password"
            type="password"
            placeholder="Type your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
          />
        </div>
        <button type="submit" className={styles.button}>Continue with Email</button>
      </form>
      <p className={styles.switchMode}>
        Already have an account?
        <button onClick={onSwitchToSignIn} className={styles.switchButton}>
          Log In
        </button>
      </p>
      <p className={styles.signAgreement}>By signing up, you agree to our <a href="#">Terms of Service</a> & <a href="#">Privacy Policy</a></p>
    </div>
  );
};

export default SignUpScreen;
