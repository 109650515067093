import React from 'react';
import './FloatingToolbar.css';
import {UndoIcon, RedoIcon, BoldIcon, ItalicIcon, UnderlineIcon, UpperCaseIcon, WriteIcon, RewriteIcon, StyleIcon} from './SVGLibrary';

const FloatingToolbar = ({ onRewrite, editor }) => {
  const toggleBold = () => {
    editor.chain().toggleBold().run();
  };

  const toggleItalic = () => {
    editor.chain().toggleItalic().run();
  };

  const toggleUnderline = () => {
    editor.chain().toggleUnderline().run();
  };

  const toggleHighlight = () => {
    editor.chain().toggleHighlight().run();
  };

  const toggleHeading = () => {
    editor.chain().toggleHeading({ level: 1 }).run();
  };

  return (
    <div className="floating-toolbar-container">
      <div className="floating-toolbar">
        <div className="toolbar-group toolbar-group-left">
          <button onClick={() => editor.chain().focus().undo().run()}>
            <UndoIcon />
          </button>
          <button onClick={() => editor.chain().focus().redo().run()}>
            <RedoIcon />
          </button>
        </div>
        <div className="toolbar-divider">
        </div>
        <div className="toolbar-group toolbar-group-middle">
          <button className='bold-button' onClick={toggleBold}>
            <BoldIcon />
          </button>
          <button className='italic-button' onClick={toggleItalic}>
            <ItalicIcon />
          </button>
          <button className='underline-button' onClick={toggleUnderline}>
            <UnderlineIcon />
          </button>
          <button className='highlight-button' onClick={toggleHighlight}>
            <UpperCaseIcon />
          </button>
          <button className='header-one-button' onClick={toggleHeading}>
            H1
          </button>
        </div>
        <div className="toolbar-divider">
        </div>
        <div className="toolbar-group toolbar-group-right">
          <div className='toolbar-group-right-box'>
            <WriteIcon />
            <button>Write</button>
          </div>
          <div className='toolbar-group-right-box' onClick={onRewrite}>
            <RewriteIcon />
            <button>Rewrite</button>
          </div>
          <div className='toolbar-group-right-box'>
            <StyleIcon />
            <button>Style</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloatingToolbar;