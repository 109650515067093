const AI_API_URL = process.env.REACT_APP_AI_API_URL || 'https://pagerift-writer.ue.r.appspot.com/api';

const streamToText = async (response) => {
  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  let result = '';

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    result += decoder.decode(value, { stream: true });
  }

  return result.split('---').filter(text => text.trim() !== '');
};

export const bubbleMenuRewrite = async (text, option, worldId) => {
  try {
    let endpoint;
    switch (option) {
      case 'rephrase':
        endpoint = 'rephrase_stream';
        break;
      case 'short':
        endpoint = 'shorten_stream';
        break;
      case 'distinctive':
        endpoint = 'distinctive_stream';
        break;
      case 'emotional':
        endpoint = 'emotional_stream';
        break;
      case 'romantic':
        endpoint = 'romantic_stream';
        break;
      default:
        throw new Error('Invalid rewrite option');
    }

    console.log('Sending request with worldId:', worldId);

    const requestBody = { text, num_versions: 4 };
    if (worldId && worldId !== 'undefined' && worldId !== 'null') {
      requestBody.world_id = worldId;
    }

    console.log(`Sending request to ${AI_API_URL}/${endpoint}`);
    console.log(`World ID: ${worldId}`);
    console.log(`Request body: ${JSON.stringify(requestBody)}`);

    const response = await fetch(`${AI_API_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    console.log(`Response status: ${response.status}`);
    console.log(`Response headers: ${JSON.stringify(Object.fromEntries(response.headers))}`);

    if (!response.ok) {
      const errorText = await response.text();
      console.error(`Error response: ${errorText}`);
      throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
    }

    const results = await streamToText(response);
    return results;
  } catch (error) {
    console.error('Error in bubbleMenuRewrite:', error);
    throw error;
  }
};
