import React, { useState, useRef, useEffect } from 'react';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import TiptapEditor from './EditorContent';
import './MainEditor.css';
import { supabase } from '../supabaseClient';
import CharacterCount from './CharacterCount';
import HorizontalHeader from './HorizontalHeader';

const MainEditor = ({ user, project, worldId: initialWorldId }) => {
  const [editorState, setEditorState] = useState(null);
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [originalText, setOriginalText] = useState('');
  const [rewriteOptions, setRewriteOptions] = useState([]);
  const [operationType, setOperationType] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [worldId, setWorldId] = useState(initialWorldId);
  const editorRef = useRef(null);

  useEffect(() => {
    loadProjectData(project.id);
  }, [project]);

  const loadProjectData = async (projectId) => {
    setIsLoading(true);
    const response = await supabase
      .from('projects')
      .select('*')
      .eq('id', projectId)
      .single();

    console.log('Full Supabase response:', response);

    if (response.error) {
      console.error('Error loading project data:', response.error);
    } else if (response.data) {
      setEditorState(response.data.content || { type: "doc", content: [{ type: "paragraph" }] });
      setTitle(response.data.title || '');
      console.log('Project data loaded:', response.data);
      // Add this line to log the worldId
      console.log('World ID from project data:', response.data.world_id);
      setWorldId(response.data.world_id);
    }
    setIsLoading(false);
  };

  const saveProjectData = async (newContent, newTitle) => {
    setIsSaving(true);
    console.log('Saving project data:', newContent);
    const { data, error } = await supabase
      .from('projects')
      .update({ 
        content: newContent, 
        title: newTitle,
        updated_at: new Date() 
      })
      .eq('id', project.id);

    if (error) {
      console.error('Error saving project data:', error);
    } else {
      console.log('Project saved successfully');
    }
    setIsSaving(false);
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    saveProjectData(editorState, newTitle);
  };

  const AI_API_URL = process.env.REACT_APP_AI_API_URL || 'https://pagerift-writer.ue.r.appspot.com/api';

  const handleRewrite = async () => {
    console.log("handleRewrite called");
    console.log("World ID:", worldId);
    const startTime = performance.now();
    
    if (!editorRef.current) {
      console.error("Editor reference is not available");
      return;
    }

    const selectedText = editorRef.current.state.doc.textBetween(
      editorRef.current.state.selection.from,
      editorRef.current.state.selection.to
    );

    console.log("Selected text:", selectedText);

    if (!selectedText) {
      alert('Please select some text to rewrite.');
      return;
    }
  
    setOriginalText(selectedText);
  
    try {
      const timestamp = Date.now();
      const response = await fetch(`${AI_API_URL}/rewrite_stream`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: selectedText, num_versions: 4, timestamp, world_id: worldId }),
        credentials: 'include',
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} ${errorText}`);
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
  
      let buffer = '';
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        buffer += decoder.decode(value, { stream: true });
        const versions = buffer.split('---');
        
        // Process complete versions
        for (let i = 0; i < versions.length - 1; i++) {
          const version = versions[i].trim();
          if (version) {
            setRewriteOptions(prev => [...prev, version]);
          }
        }
        
        // Keep the incomplete version in the buffer
        buffer = versions[versions.length - 1];
      }
  
      // Process any remaining content in the buffer
      if (buffer.trim()) {
        setRewriteOptions(prev => [...prev, buffer.trim()]);
      }
  
      const totalTime = performance.now() - startTime;
      console.log(`Total rewrite time: ${totalTime}ms`);
    } catch (error) {
      console.error('Error details:', error);
      alert(`An error occurred while processing your rewrite request: ${error.message}`);
    }
  };

  const handleInsert = (rewrittenText) => {
    if (editorRef.current) {
      const { from, to } = editorRef.current.state.selection;
      editorRef.current.commands.setTextSelection({ from, to });
      editorRef.current.commands.insertContent(rewrittenText);
    }
  };

  const handleBookmark = (rewrittenText) => {
    // Implement bookmark functionality here
    console.log("Bookmarked:", rewrittenText);
  };

  if (isLoading) {
    return <div>Loading project...</div>; // Or a more sophisticated loading indicator
  }

  return (
    <div className="main-editor-container">
      <LeftSidebar />
      <div className="editor-right-wrapper">
        <HorizontalHeader characterCount={characterCount} isSaving={isSaving} />
        <div className="editor-content-wrapper">
          <TiptapEditor 
            editorState={editorState} 
            setEditorState={(newState) => {
              setEditorState(newState);
              saveProjectData(newState, title);
            }}
            title={title}
            onTitleChange={handleTitleChange}
            onRewrite={handleRewrite}
            setRewriteOptions={setRewriteOptions}
            setOriginalText={setOriginalText}
            setOperationType={setOperationType}
            ref={editorRef}
            setCharacterCount={setCharacterCount}
            worldId={worldId}
          />
          <RightSidebar 
            rewriteOptions={rewriteOptions}
            originalText={originalText}
            operationType={operationType}
            onInsert={handleInsert}
            onBookmark={handleBookmark}
          />
        </div>
      </div>
    </div>
  );
};

export default MainEditor;